<template>
  <div>
    <CContainer>
      <CRow>
        <CCol md="6">
          <CCardGroup>
            <CCard>
              <CCardHeader>
                <h3 v-if="this.predictionData.id">
                  Редактирование предсказания
                </h3>
                <h3 v-else>Создание предсказания</h3>
              </CCardHeader>
              <CCardBody>
                <CForm @submit.prevent="handleSubmit">
                  <CInput
                    v-model="predictionData.lead"
                    label="Лид"
                    placeholder=""
                    @input="requestPredictionUpdate"
                  >
                  </CInput>
                  <div v-if="this.predictionData.id">
                    <CTextarea
                      rows="6"
                      v-model="predictionData.text"
                      label="Текст"
                      placeholder=""
                      @input="requestPredictionUpdate"
                    >
                    </CTextarea>
                    <CSelect
                      :value.sync="predictionData.person_type"
                      label="Тип личности"
                      :options="personTypes"
                      @change="requestPredictionUpdate"
                    />
                    <CSelect
                      :value.sync="predictionData.zodiac_sign"
                      label="Знак зодиака"
                      :options="zodiacSigns"
                      @change="requestPredictionUpdate"
                    />
                    <CInput
                      v-model="predictionData.day"
                      label="Порядковый номер в гороскопе"
                      placeholder=""
                      @input="requestPredictionUpdate"
                      type="number"
                    >
                    </CInput>
                    <CInputFile
                      label="Картинка или видео предсказания"
                      placeholder=""
                      @change="uploadPredictionImage"
                    >
                    </CInputFile>
                    <div v-if="image">
                      <img style="max-width: 400px" :src="image" />
                    </div>
                    <br />
                    <br />
                    <br />
                    <CInputFile
                      label="Картинка шера предсказания"
                      placeholder=""
                      @change="uploadPredictionShareImage"
                    >
                    </CInputFile>
                    <div v-if="shareImage">
                      <img style="max-width: 400px" :src="shareImage" />
                    </div>
                    <br />
                  </div>
                  <CRow>
                    <CCol>
                      <CButton
                        v-if="this.predictionData.id"
                        color="success"
                        type="submit"
                      >
                        Сохранить
                      </CButton>
                      <CButton v-else color="success" type="submit">
                        Создать
                      </CButton>
                    </CCol>
                    <CButton
                      v-if="predictionData.id"
                      color="success"
                      type="button"
                      @click="
                        router.push({
                          name: 'prediction-create',
                        })
                      "
                    >
                      Добавить ещё
                    </CButton>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { makeCoreUICSelectList, updateViewURL } from "../helpers";
import { debounce } from "lodash";
import { router } from "../router";
export default {
  name: "PredictionForm",
  data() {
    return {
      router,
      image: null,
      shareImage: null,
      personTypes: [],
      zodiacSigns: [],
      predictionData: {
        id: this.$route.params.id,
        lead: null,
        image: null,
        share_image: null,
        text: null,
        person_type: null,
        zodiac_sign: null,
        day: null,
      },
    };
  },

  created() {
    this.storeSubscription = this.$store.subscribe((mutation) => {
      if (mutation.type === "zodiacSigns/getAllSuccess") {
        this.zodiacSigns = makeCoreUICSelectList(
          "name",
          "id",
          mutation.payload
        );
      } else if (mutation.type === "personTypes/getAllSuccess") {
        this.personTypes = makeCoreUICSelectList(
          "name",
          "id",
          mutation.payload
        );
      } else if (mutation.type === "predictions/getByIdSuccess") {
        this.initPredictionForm(mutation.payload);
      } else if (mutation.type === "predictions/createSuccess") {
        this.initPredictionForm(mutation.payload);
        updateViewURL(router, {
          name: "prediction-edit",
          params: { id: this.predictionData.id },
        });
      }
    });
    if (this.predictionData.id) {
      this["predictions/getById"](this.predictionData.id);
    }
    this["zodiacSigns/getAll"]();
    this["personTypes/getAll"]();
  },
  beforeDestroy() {
    if (this.storeSubscription) {
      this.storeSubscription();
    }
  },
  methods: {
    ...mapActions([
      "predictions/create",
      "predictions/update",
      "predictions/getById",
      "predictions/saveImage",
      "predictions/saveShareImage",
      "zodiacSigns/getAll",
      "personTypes/getAll",
    ]),

    requestPredictionUpdate: debounce(function () {
      this.updatePrediction();
    }, 500),

    updatePrediction() {
      let method;
      if (this.predictionData.id) {
        method = "predictions/update";
      } else {
        method = "predictions/create";
      }
      const dataToSend = Object.assign({}, this.predictionData);
      delete dataToSend.image;
      delete dataToSend.shareImage;
      if (dataToSend.person_type === 0) {
        dataToSend.person_type = null;
      }
      if (dataToSend.zodiac_sign === 0) {
        dataToSend.zodiac_sign = null;
      }
      this[method](dataToSend);
    },
    uploadPredictionImage(files) {
      const file = files[0];
      const reader = new FileReader();
      const saveThis = this;

      reader.addEventListener(
        "load",
        function () {
          saveThis.image = reader.result;
          saveThis["predictions/saveImage"]({
            id: saveThis.predictionData.id,
            dataUrl: saveThis.image,
          });
        },
        false
      );
      reader.readAsDataURL(file);
    },
    uploadPredictionShareImage(files) {
      const file = files[0];
      const reader = new FileReader();
      const saveThis = this;

      reader.addEventListener(
        "load",
        function () {
          saveThis.shareImage = reader.result;
          saveThis["predictions/saveShareImage"]({
            id: saveThis.predictionData.id,
            dataUrl: saveThis.shareImage,
          });
        },
        false
      );
      reader.readAsDataURL(file);
    },
    initPredictionForm(data) {
      this.predictionData.id = data.id;
      this.predictionData.lead = data.lead;
      this.predictionData.share_image = data.share_image;
      this.predictionData.text = data.text;
      this.predictionData.person_type = data.person_type;
      this.predictionData.zodiac_sign = data.zodiac_sign;
      this.predictionData.day = data.day;
      this.shareImage = data.share_image;
    },
  },
};
</script>
